//
// forms.scss
//

// Form-control light

// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}

select.form-control {
    &:not([size]):not([multiple]) {
        height: $input-height;
    }
}

select.form-control-sm {
    &:not([size]):not([multiple]) {
        height: $input-height-sm;
    }
}

// Form-control light
.form-control-light {
    background-color: var(--#{$prefix}light) !important;
    border-color: var(--#{$prefix}light) !important;
}


// Comment box
.comment-area-box {
    .form-control {
        border-color: var(--#{$prefix}gray-300);
        border-radius: $input-border-radius $input-border-radius 0 0;
    }

    .comment-area-btn {
        background-color: var(--#{$prefix}gray-100);
        padding: 10px;
        border: 1px solid var(--#{$prefix}gray-300);
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}



// Search box
.search-bar {
    .form-control {
        padding-left: 40px;
        padding-right: 20px;
        border-radius: 30px;
    }

    span {
        position: absolute;
        z-index: 10;
        font-size: 16px;
        line-height: $input-height;
        left: 13px;
        top: -2px;
        color: $text-muted;
    }
}

// Show/Hide Password
.password-eye {
    cursor: pointer;

    &:before {
        font-family: "Material Design Icons";
        content: "\F06D0";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\F06D1";
        }
    }
}

.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1;
}

.is-invalid__control {
    border-color: var(--ct-danger) !important;
    border-radius: 0.25rem;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f1556c%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23f1556c%27 stroke=%27none%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right 3rem center;
    background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.is-invalid__control--is-focused{
    box-shadow: 0 0 0 0.15rem rgba(var(--ct-danger-rgb), 0.25) !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}